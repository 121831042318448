import {DocumentModel, DocumentsFeatureType} from '@matchsource/models/documents';

export class GetWorkupAttachments {
  static readonly type = '[Order Attachments] Get Order Attachments';
  constructor(
    public readonly workupId: number,
    public readonly isLegacyOrder = false
  ) {}
}

export class DeleteOrderAttachment {
  static readonly type = '[Order Attachments] Delete Order Attachments';
  constructor(
    public readonly workupId: number,
    public readonly attachmentsIds: number[],
    public readonly isLegacyOrder = false
  ) {}
}

export class AddOrderAttachments {
  static readonly type = '[Order Attachments] Add Order Attachments';
  constructor(
    public readonly workupId: number,
    public readonly attachments: DocumentModel[],
    public readonly isLegacyOrder = false
  ) {}
}

export class BulkUpdateAttachments {
  static readonly type = '[Order Attachments] Bulk Add Attachments';
  constructor(
    public readonly workupId: number,
    public readonly attachments: DocumentModel[],
    public readonly isLegacyOrder = false
  ) {}
}

export class LoadOrderAttachmentExtensions {
  static readonly type = '[Order Attachments] Load Order Attachment Extensions';
  constructor(public readonly documentsFeatureType: DocumentsFeatureType) {}
}

export class GetWorkupByOrderId {
  static readonly type = '[Order Attachments] Get Order Workup by order id';
  constructor(
    public readonly orderId: MsApp.Guid,
    public readonly isLegacyOrder = false,
    public readonly isInternationalFormsAvailable = false
  ) {}
}

export class GetNewWorkupIdByOrderId {
  static readonly type = '[Order Attachments] Get New Order Workup Id by order id';
  constructor(public readonly orderId: MsApp.Guid) {}
}

export class ClearAttachmentsState {
  static readonly type = '[Order Attachments] Clear Attachments';
}

export class ReassignWorkupAttachments {
  static readonly type = '[Workup Attachments] Reassign Workup Attachments';
  constructor(
    public readonly workupId: number,
    public readonly orderGuid: MsApp.Guid,
    public readonly isLegacyOrder = false
  ) {}
}

export class DeleteWorkupInProgressAttachments {
  static readonly type = '[Workup Attachments] Delete Workup In Progress Attachments';
  constructor(
    public readonly workupId: number,
    public readonly isLegacyOrder = false
  ) {}
}
